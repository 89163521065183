import React from "react"
import Logo from "./logo"
import { graphql, useStaticQuery } from "gatsby"
import UniversalLink from "./universalLink"
import Heading from "./heading"
import Icon from "./icon"

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      sanitySiteSettings {
        id
        menuItems {
          title {
            br
          }
          slug {
            br {
              current
            }
          }
        }

        footer {
          heading {
            br
          }
          text {
            br
          }
          socialLinks {
            title
            socialMediaLink
            icon {
              title
            }
          }
        }
      }
      allSanityPage(
        filter: {
          parentPage: { slug: { br: { current: { eq: "solucoes" } } } }
        }
        sort: { fields: _updatedAt }
      ) {
        edges {
          node {
            id
            title {
              br
            }
            slug {
              br {
                current
              }
            }
          }
        }
      }
    }
  `)

  const menuItems = data.sanitySiteSettings.menuItems

  const ourServices = data.allSanityPage.edges

  return (
    <footer className="footer bg-primary-900 py-16">
      <div className="container">
        <div className="w-40 text-left">
          <UniversalLink className="logo text-left" to="/">
            <Logo className="text-white fill-current" />
          </UniversalLink>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-12 gap-6 mt-8">
          <div className="lg:col-span-3 text-sm">
            <Heading level="xs">
              {data.sanitySiteSettings.footer.heading.br}
            </Heading>
            <p>{data.sanitySiteSettings.footer.text.br}</p>

            <div className="social mt-8">
              <ul className="flex space-x-4">
                {data.sanitySiteSettings.footer.socialLinks.map(socialItem => {
                  return (
                    <li key={socialItem.icon.title}>
                      <UniversalLink to={socialItem.socialMediaLink}>
                        <Icon
                          id={socialItem.icon.title}
                          className={`mb-0 icon--white w-4 transition duration-700 transform hover:-translate-y-1 hover:text-accent`}
                        />
                      </UniversalLink>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>

          <div className="lg:col-start-5 lg:col-span-2">
            <Heading level="xs">Navegação</Heading>

            <nav className="space-y-2 mt-6 text-sm">
              {menuItems &&
                menuItems.map((item, index) => {
                  const link =
                    "home" === item.slug.br.current ? "" : item.slug.br.current

                  return (
                    <UniversalLink
                      className="block transition duration-700 hover:text-accent"
                      key={index}
                      to={`/${link}`}
                    >
                      {"home" === item.title.br ? "Home" : item.title.br}
                    </UniversalLink>
                  )
                })}
            </nav>
          </div>
          <div className=" lg:col-span-2">
            <Heading level="xs">Nossas Soluções</Heading>

            <nav className="space-y-2 mt-6 text-sm">
              {ourServices &&
                ourServices.map((item, index) => {
                  const link =
                    "home" === item.node.slug.br.current
                      ? ""
                      : item.node.slug.br.current

                  return (
                    <UniversalLink
                      className="block transition duration-700 hover:text-accent"
                      key={index}
                      to={`/${link}`}
                    >
                      {"home" === item.node.title.br
                        ? "Home"
                        : item.node.title.br}
                    </UniversalLink>
                  )
                })}
            </nav>
          </div>
          <div className=" lg:col-span-2">
            <Heading level="xs">Onde Estamos</Heading>

            <div className="text-sm">
              <p>
                Av. Ceará, 1093
                <br />
                São João,
                <br /> Porto Alegre, RS
                <br />
                CEP: 90240-511 <br />
              </p>
              <p>
                <a
                  onClick={() => {
                    dataLayer.push({
                      event: "click-phone-number",
                    })
                  }}
                  href="tel:+555130623090"
                >
                  (51) 3062-3090
                </a>
              </p>

              <p>
                <UniversalLink
                  to="/contato"
                  className="block font-black text-base transition duration-700 hover:text-accent"
                >
                  Fale Conosco
                </UniversalLink>
              </p>
            </div>
          </div>

          <div className=" lg:col-span-2">
            <Heading level="xs" className="">
              <UniversalLink
                to="https://www.instagram.com/360_arquitetura/"
                className="font-black text-base transition duration-700 hover:text-accent flex items-center space-x-2 w-full"
              >
                <Icon id={`instagram`} className={`mb-0 icon--white`} />
                <span>Instagram</span>
              </UniversalLink>
            </Heading>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
